/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import urlFor from 'utils/helpers/image-url';
import { buildImageObj } from 'utils/helpers';

const SEO = ({
  description,
  meta,
  keywords,
  title,
  lang,
  bodyAttrs,
  image,
  ...props
}) => {
  const metaDescription = description || '';
  let imageUrl;

  if (image) {
    imageUrl = urlFor(buildImageObj(image)).auto('format').url();
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      bodyAttributes={bodyAttrs}
      title={title || 'Sunny at Sea'}
      titleTemplate={title ? '%s | Sunny at Sea' : 'Sunny at Sea'}
      meta={[
        {
          charSet: 'utf-8',
        },
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: 'Sunny at Sea',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        ...(!imageUrl
          ? []
          : [
              {
                name: 'twitter:image',
                content: imageUrl,
              },
              {
                name: 'og:image',
                content: imageUrl,
              },
            ]),
        ...(meta.filter((e) => e.name === 'robots').length > 0
          ? []
          : [{ name: 'robots', content: 'index, follow' }]),
      ]
        .concat(
          keywords && keywords.length > 0
            ? {
                name: 'keywords',
                content: keywords.join(', '),
              }
            : [],
        )
        .concat(meta)}
      {...props}
    />
  );
};

export default SEO;

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
  description: undefined,
  bodyAttrs: undefined,
  title: undefined,
  image: undefined,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  bodyAttrs: PropTypes.object,
  image: PropTypes.shape({
    asset: PropTypes.object,
  }),
};
